'use strict';

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output plugins/global/scripts
 */

// jquery
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;

import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/ui/widgets/draggable';

import 'blueimp-file-upload';

import 'jquery-serializejson';
import 'select2';
// import 'select2/dist/js/select2.full.min';
require('select2/dist/js/i18n/zh-CN');

import 'magnific-popup/dist/jquery.magnific-popup.min';

import 'daterangepicker';

import 'bootstrap';
import bootbox from 'bootbox';

window.AconfGlobal = window.AconfGlobal || {};
window.AconfGlobal.bootbox = bootbox;